<template>
    <div class="board">            
        <article v-for="(person, id) in directors" :key="id">                
            <p class="name uppercase" v-html="person.name"/>
            <p class="position" v-html="person.position"/>            
            <p class="company" v-html="person.company"/>            
        </article>
    </div>
</template>

<script>
    export default {
        name:'EventFeatures',      
        data: () => ({ 
            directors: [
                {
                    name:"Lindsay <span>Amstutz</span>",
                    position:"Chief Media Officer",
                    company:"Oneteam Partners"
                },
                {
                    name:"Greg <span>Ashlock</span>",
                    position:"President",
                    company:"Iheartmedia Markets Group"
                },
                {
                    name:"John <span>Bendheim</span>",
                    position:"CEO",
                    company:"Bendheim Enterprises Inc."
                },
                {
                    name:"Brett <span>Bittel</span>",
                    position:"Bittel Enterprises, Inc.",
                    company:""
                },
                {
                    name:"Jeanie <span>Buss</span>",
                    position:"CEO & Governor",
                    company:"Los Angeles Lakers"
                },
                {
                    name:"Scott <span>Clemmer</span>",
                    position:"Vice President/General Manager",
                    company:"Enterprise Rent-A-Car"
                },
                {
                    name:"Ron <span>Cohen</span>",
                    position:"Partner",
                    company:"Levatolaw"
                },
                {
                    name:"Victor <span>Coleman</span>",
                    position:"Chairman & CEO",
                    company:"Hudson Pacific Properties, Inc."
                },
                {
                    name:"Kevin <span>Demoff</span>",
                    position:"Chief Operating Officer",
                    company:"Los Angeles Rams"
                },
                {
                    name:"James G. <span>Ellis</span>",
                    position:"Former Dean",
                    company:"USC Marshall School Of Business"
                },
                {
                    name:"Michael <span>Finley</span>",
                    position:"CEO",
                    company:"Boingo"
                },
                {
                    name:"Jeffrey <span>Glassman</span>",
                    position:"CEO",
                    company:"Covington Capital Management"
                },
                {
                    name:"Blanca <span>Gonzalez</span>",
                    position:"Senior Vice President, Global Merchandising",
                    company:"lululemon"
                },
                {
                    name:"Bob <span>Graziano</span>",
                    position:"Vice Chairman",
                    company:"J.P. Morgan Private Bank"
                },
                {
                    name:"Larry <span>Green</span>",
                    position:"Managing Partner",
                    company:"L Green Investment & Development"
                },
                {
                    name:"Bill <span>Hanway</span>",
                    position:"Executive Vice President",
                    company:"Global Sports Leader/AECOM Services Group"
                },                
                {
                    name:"Karina <span>Herold</span>",
                    position:"Senior Associate Director Of Athletics, Senior Woman Administrator",
                    company:"Pepperdine Athletics"
                },                
                {
                    name:"Karen <span>Irwin</span>",
                    position:"President & Chief Operating Officer",
                    company:"Universal Studios Hollywood"
                },
                {
                    name:"Sabrina <span>Kay</span>",
                    position:"CEO, Fremont Private Investments, Inc.",
                    company:""
                },
                {
                    name:"Kevin <span>Kunkel</span>",
                    position:"West U.S. Regional Vice President",
                    company:"T-Mobile"
                },
                {
                    name:"Craig <span>Levra</span>",
                    position:"Chief Operating Officer",
                    company:"Goodwill Southern California"
                },
                {
                    name:"Mark <span>Liberman</span>",
                    position:"Chairman",
                    company:"Alzheimer’s Greater Los Angeles"
                },
                {
                    name:"Greg <span>Longstreet</span>",
                    position:"CEO",
                    company:"Del Monte Foods"
                },
                {
                    name:"Kyle <span>Mabry</span>",
                    position:"Vice President",
                    company:"American Airlines"
                },
                {
                    name:"Kevin <span>Mayer</span>",
                    position:"Chairman",
                    company:"DAZN"
                },
                {
                    name:"Madeleine <span>Mcray</span>",
                    position:"Area Vice President, Health & Life Sciences",
                    company:"Salesforce"
                },            
                {
                    name:"Michael <span>Meyer</span>",
                    position:"Of Counsel",
                    company:"Glaser Weil"
                },
                {
                    name:"Paul <span>Miller</span>",
                    position:"Managing Director",
                    company:"First Foundation Advisors"
                },
                {
                    name:"Dave <span>Nash</span>",
                    position:"Regional President",
                    company:"Qualcomm North America & Australia/Qualcomm Technologies, Inc."
                },                
                {
                    name:"Mike <span>Persson</span>",
                    position:"General Manager, Partner",
                    company:"Bright Event Rentals"
                },
                {
                    name:"Kevin <span>Reilly</span>",
                    position:"LASEC Board Member",
                    company:""
                },
                {
                    name:"James <span>Rishwain, Jr.</span>",
                    position:"Chair Emeritus",
                    company:"Pillsbury Winthrop Shaw Pittman LLP"
                },
                {
                    name:"David <span>Rone</span>",
                    position:"Senior Managing Director",
                    company:"Guggenheim Partners, Llc"
                },
                {
                    name:"Edward <span>Roski, Jr.</span>",
                    position:"President & Chairman Of The Board",
                    company:"Majestic Realty Co."
                },
                {
                    name:"Alan <span>Rothenberg</span>",
                    position:"Chairman",
                    company:"1st Century Bank"
                },
                {
                    name:"Kathryn <span>Schloessman</span>",
                    position:"President & CEO",
                    company:"Los Angeles Sports & Entertainment Commission"
                },
                {
                    name:"Carl <span>Schuster</span>",
                    position:"CEO & Managing Partner",
                    company:"Wolfgang Puck Catering"
                },
                {
                    name:"John <span>Semcken</span>",
                    position:"Senior Vice President",
                    company:"Majestic Realty Co."
                },
                {
                    name:"Jeff <span>Shell</span>",
                    position:"CEO",
                    company:"Universal Filmed Entertainment Group, Universal Studios"
                },
                {
                    name:"Jeff <span>Sigmen</span>",
                    position:"Chief Customer Officer",
                    company:"Reyes Coca-Cola Bottling"
                },
                {
                    name:"Renata <span>Simril</span>",
                    position:"President & CEO",
                    company:"La84 Foundation"
                },
                {
                    name:"A.G. <span>Spanos</span>",
                    position:"President, Business Operations",
                    company:"Los Angeles Chargers"
                },
                {
                    name:"Thomas <span>Tellefsen</span>",
                    position:"Principal",
                    company:"Tellefsen Investments, Inc."
                },
                {
                    name:"Kevin <span>Tsujihara</span>",
                    position:"Ely Road LLC",
                    company:""
                },
                {
                    name:"Susan <span>Hirsch Wohl</span>",
                    position:"Director & CFO",
                    company:"Bob And Nita Hirsch Family Foundation"
                },
                {
                    name:"Michael <span>Yamaki</span>",
                    position:"Board Member",
                    company:"The Riviera Country Club"
                }
            ]
        }),        
    }
</script>
<style lang="scss">    
    .board {
        max-width: 1024px;
        margin:0 auto;         
        gap:30px;
        margin-bottom:35px;
          article {
            display: block;
            text-align: center;
            &:not(:last-child) {
                margin-bottom:24px;
            }
            p {
                margin:0;
                padding:0;
                color: #ADADAD;
                font-family: "Gotham Narrow";
                font-size: 0.85em;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 1.5;
            }
            .name {            
                color:#fff;
                font-family: "Gotham Narrow";
                font-size: 0.9em;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 1;
                margin-bottom:8px;
                span {
                    color: #C2AB64;
                }
            }
        }
    }
  
     @media (min-width:360px) {              
         .board {
            display: grid;        
            grid-template-columns: repeat(2, 1fr);
            margin:1em;
            article {
                display: inline-block;
                text-align: left;
                margin:0;
                vertical-align: top;
            }
         }
         
    }
    @media (min-width:500px) {    
        .board {          
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (min-width:600px) {    
        .board {          
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (min-width:700px) {    
        .board {          
            grid-template-columns: repeat(5, 1fr);
        }
    }
</style>