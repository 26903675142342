<template>
    <section>
        <Gallery title="ChampionLA" subtitle="Who is <br/>ChampionLA?" :images="[
        {
            title:'SOFI Stadium',
            file:'who-is-championla.jpg'
        }        
        ]"/>         
        <section id="board-of-directors">
            <SectionIntro>
                <template v-slot:intro>
                    <h2>
                        <div class="uppercase">Board of <span class="gold">Directors</span></div>
                    </h2>            
                    <p>Our mission is to help build a brighter, more vibrant city through the proven economic engine of hosting successful high-profile events. We work hard to ensure that Los Angeles is selected to host events that deliver significant and equitable economic benefits, huge community engagement opportunities, and extraordinary visibility.</p>
                </template>
            </SectionIntro> 
            <BoardOfDirectors />
        </section>         
        <section id="donors">
            <h3 class='gold uppercase'>Donors</h3>          
            <div class="donors-holder">
                <Donors />  
            </div>
        </section>        
        <Linear forcedPath='/your-opportunity/packages' forcedTitle='Packages'/>     
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import SectionIntro from '@/patterns/SectionIntro';
import BoardOfDirectors from '@/components/BoardOfDirectors';
import Donors from '@/components/Donors';
import Linear from '@/patterns/Linear';
export default {
    name:'WhoIsChampionLA',
    components:{Gallery, SectionIntro, BoardOfDirectors, Donors, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>    
    h3 {
        text-align: center;
    }
    #donors {
        padding:95px 0;
    }
    #board-of-directors, .donors-holder {          
        &:before, &:after {
            width:100%;
            max-width: 1024px;
            margin:0 auto;
            height:1px;
            background:#414141;
            display: block;
            content:" ";
        }  
    }
    .donors-holder {
        &:before {
            margin-bottom: 35px;
        }
        &:before, &:after {
            background:#D9D9D9;
        }
    }
    #board-of-directors {
        padding-bottom:125px;    
        background: linear-gradient(135deg, #202020 0%, #161616 100%);
        padding-top:35px;
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column;   
        p {
            &:last-child{
                margin-bottom:95px;
            }
        }                        
    }  
</style>
