<template>
    <section>
        <Gallery title="ChampionLA" subtitle="Use of <br/>Funds" :images="[
        {
            title:'SOFI Stadium',
            file:'use-of-funds.jpg'
        }        
        ]"/>         
        <section id="use-of-funds">
            <SectionIntro>
                <template v-slot:intro>
                    <h2>
                        <div class="uppercase"><span class="gold">ChampionLA</span> serves to fulfill the<br/>financial obligations of hosting<br/>significant global events in Los Angeles.</div>
                    </h2>            
                    <p>Ultimately, this financial support helps to elevate Los Angeles’ reputation as the premier hosting destination and encourages these events to return.</p>
                    <p>This is not about raising money for individual events. Instead, with your help, we can fulfill our mission and build a brighter, more vibrant city through a proven economic engine. An investment in ChampionLA and Los Angeles will pay dividends for years to come.</p>
                    <p class='de-emphasize'>ChampionLA is a program of LASEC and LASEC is recognized as a tax-exempt organization under section 501(c)(3) of the Internal Revenue Code.  Our federal tax identification number is 95-4541188.</p>
                </template>
            </SectionIntro> 
        </section>         
        <SuperBowlExpenses /> 
        <Linear forcedPath='/champion-la/who-is-champion-la' forcedTitle='Who is ChampionLA?'/>     
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import SectionIntro from '@/patterns/SectionIntro';
import SuperBowlExpenses from '@/components/SuperBowlExpenses';
import Linear from '@/patterns/Linear';
export default {
    name:'WhoIsChampionLA',
    components:{Gallery, SectionIntro, Linear, SuperBowlExpenses},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>    
    h3 {
        text-align: center;
    }    
    p {
        max-width: 550px;
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
    #use-of-funds {   
        .de-emphasize {
            color:#C7C7C7;
        }       
        &:before, &:after {
            width:100%;
            max-width: 1024px;
            margin:0 auto;
            height:1px;
            background:#414141;
            display: block;
            content:" ";
        }  
    }    
    #use-of-funds {
        padding-bottom:125px;    
        background: linear-gradient(135deg, #202020 0%, #161616 100%);
        padding-top:35px;
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column;   
        p {
            &:last-child{
                margin-bottom:95px;
            }
        }                        
    }  
</style>
