<template>
  <div class="event-features-holder">            
    <section class="event-features">            
            <div v-for="(feature, id) in features" :key="id" :style="{
                    'background-image':'url(' + require('@/assets/cards/' + feature.image) + ')',
                }"
            >                
                <div class="details">
                    <div class="text">
                        <p class="title uppercase" v-html="feature.title"/>
                        <p class="subtitle" v-html="feature.subtitle"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name:'EventFeatures',      
        data: () => ({ 
            features: [
                {
                    image:'roi_high-profile_taxes.jpg',
                    icon:'roi/icon_dollar.svg',
                    title:'Taxes',
                    subtitle:'Local sales tax spending goes toward city’s general fund',           
                },
                {
                    image:'roi_high-profile_procurement.jpg',
                    icon:'roi/icon_briefcase.svg',
                    title:'Procurement & Workforce <span class="gold">Development</span>',
                    subtitle:'Opportunities are created for business owners to grow the workforce',                                
                },                
                {
                    image:'roi_high-profile_infrastructure.jpg',
                    icon:'roi/icon_building.svg',
                    title:'Infrastructure <span class="gold">Investment</span>',                    
                    subtitle:'Events spur innovation and accelerated infrastructure development',            
                },                
                {
                    image:'roi_high-profile_media.jpg',
                    icon:'roi/icon_eye.svg',
                    title:'<span class="gold">Media</span> Visability',
                    subtitle:'Global eyes on Los Angeles bolsters tourism and creates future hosting opportunities',            
                },                
                {
                    image:'roi_high-profile_youth.jpg',
                    icon:'roi/icon_person.svg',
                    title:'<span class="gold">Youth</span> Engagement & Participation',
                    subtitle:'Building character and youth experience for a better future LA',            
                },                
                {
                    image:'roi_high-profile_community.jpg',
                    icon:'roi/icon_heart.svg',
                    title:'<span class="gold">Community</span> & Volunteerism',
                    subtitle:'Experiences provide volunteer opportunities and strengthen community spirit, providing intangible and wide reaching lift',            
                },                
            ],
        }),    
        
    }
</script>
<style lang="scss" scoped>
    .event-features-holder {        
        position: relative;
        &:before {
            content: " ";
            background: linear-gradient(135deg, #202020 0%, #161616 100%);      
            width: 100%;
            height:calc(100% - 100px);
            
            position: absolute;
            top:100px;
            left:0;
            
        }    
          
    }
    .event-features {        
        display: flex;        
        max-width: 1230px;
        margin:0 auto;                     
        flex-wrap: wrap;   
        &:before, &:after {
            content:" ";
            max-width: 1024px;
            margin:0 auto;
            width: 100%;
            height:1px;
            position: relative;
        }
        &:before {            
            background:#D9D9D9;
            margin-bottom:35px;                        
        }
        &:after {                                
            background:#414141;
            margin-bottom:95px;
            margin-top:35px;
        }        
        > div {
            flex-grow: 1;            
            width:100%;            
            background-color:#c1c1c1;
            background-size:cover;
            background-position: center;
            padding:40px;                        
            height:495px;
            max-height: calc(100vh - 200px);
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            user-select: none;
            box-sizing: border-box;    
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            &::after {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height:100%;
                content:" ";
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
            }           
        }
    }
    .icon {
        height: 65px;
        width: 65px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
        border-radius: 100%;
        position:absolute;
        top:40px;
        left:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {                    
            vertical-align: middle;
        }
    }
    .details {        
        text-align: left;
        display:flex;
        align-items: center;
        position: relative;
        z-index: 1;                      
        .text {
            flex-grow: 1;
            font-weight: bold;
            margin-right:1em;
        }
        .title {
            font-family: "Gotham Narrow";
            font-size: 2em;
            font-weight: 900;
            letter-spacing: 2px;
            padding-bottom:30px;
            line-height: 1;
            border-bottom:1px solid #fff;
        }
        .subtitle {            
            font-family: "Gotham Narrow";
            font-size: 1em;
            font-weight: 300;
            letter-spacing: 0;
        }
    }
    @media (min-width:825px) {      
        .event-features > div {
            max-width: calc(100%  / 2);
            margin-bottom: 0 !important;
        }          
    }
     @media (min-width:1024px) {      
        .event-features > div {
            max-width: calc(100%  / 3);
        }          
    }
</style>