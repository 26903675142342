<template>
  <section>
      <div class="investment">
        <table>
            <tr>
                <th></th>
                <th>Event</th>
                <th>Investment by Local Host Committee</th>
                <th>Return on Investment (ROI)</th>
            </tr>
            <tr>
                <td class='logo-column'><img src="@/assets/logos/roi/logo_special-oly-world-games.png" alt=""></td>
                <td><div><div class='info'>2015 Special<br />Olympic World<br />Games</div></div></td>
                <td>$100M <p>cash and in-kind</p></td>
                <td>$415M in identifiable benefits</td>
            </tr>
            <tr>
                <td class='logo-column'><img src="@/assets/logos/roi/logo_nba-all-star.png" alt=""></td>
                <td><div><div class='info'>2018 NBA<br />All-Star</div></div></td>
                <td>$200,000</td>
                <td>$116M in increased economic activity</td>
            </tr>
            <tr>
                <td class='logo-column'><img src="@/assets/logos/roi/logo_2022-super-bowl.png" alt=""></td>
                <td><div><div class='info'>2022<br />Super Bowl <p>(projected)</p></div></div></td>
                <td>$37M <p>cash and in-kind</p></td>
                <td>$572M in increased economic activity</td>
            </tr>
            <tr>
                <td class='logo-column'><img src="@/assets/logos/roi/logo_2023-college-football-playoff.png" alt=""></td>
                <td><div><div class='info'>2023 College<br />Football Playoff<br />National<br />Championship <p>(projected)</p></div></div></td>
                <td>$15M <p>cash and in-kind</p></td>
                <td>$150M in economic impact</td>
            </tr>
        </table>
      </div>
  </section>
</template>

<script>
    export default {
        name:'InvestmentTable',      
        data: () => ({
            moreInfo:false
        }),            
    }
</script>
<style lang="scss" scoped> 
    section {
        max-width: 1044px;
        margin:0 auto;
        &:before, &:after {
            content:" ";
            max-width: 1024px;
            margin:0 auto;
            width: 100%;
            height:1px;
            display: block;
        }
        &:before {            
            background:#D9D9D9;
            margin-bottom:35px;                        
        }
        &:after {                                
            background:#D9D9D9;
            margin-bottom:95px;
            margin-top:35px;
        }
    }
    .investment {
        background:#fff;
        width: 100%;                
        overflow: auto;
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    }
    table {
        text-align: left;
        font-family: "Gotham Narrow";
        color: #161616;
        padding:10px;
        width: 1044px;
        box-sizing: border-box;
    }
    tr {
        td {
            background-color: #EDEDED;
        }
        &:nth-child(2n + 1) td {
            background-color: #E1E1E1;
        }
    }
    button {
        cursor: pointer;
    }
    th {
        background-color: #C2AB64;
        color: #FFFFFF;
        font-family: "Gotham Narrow";
        font-size: 1em;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;        
        padding:10px 30px;
    }
    td {
        font-size: 1.4em;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        padding:30px;
        width:calc(100% / 3);
        &.logo-column {
            text-align: center;
            width: 100px;
            img {
                margin: 0;
            }
        }
        > div {
            display: flex;
        }
        img {
            margin-right:20px;
            width: 85px;
        }
        .info {
            margin-top:8px;
        }
    }
    p {           
        font-size: 0.6em;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1;
        padding-top:8px;
    }
    ul {
        overflow:hidden;
        max-height: 0;
        transition: max-height 0.25s ease;
        &.open {
            max-height: 400px;
        }
        li {
            &:not(:last-child) {
                margin-bottom:16px;
            }
        }
    }
     @media (min-width:1024px) {      
              
    }
</style>