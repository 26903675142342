<template>
    <section>
        <Gallery title="ChampionLA" subtitle="What is the<br/>ROI on Events?" :images="[
        {
            title:'',
            file:'what-is-the-roi-on-events.jpg'
        },       
        ]"/>        
        <div id="investment">
            <h3>An Investment in <span class="gold">ChampionLA</span> Pays Off</h3>
            <InvestmentTable />
        </div>        
        <div id="high-profile-events">
            <h3>High Profile Events Keep on Giving</h3>
            <EventFeatures />
        </div>               
        <Linear forcedPath='/champion-la/use-of-funds' forcedTitle='Use of Funds'/> 
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import EventFeatures from '@/components/EventFeatures';
import InvestmentTable from '@/components/InvestmentTable';
import Linear from '@/patterns/Linear';
export default {
    name:'WhyChampionLA',
    components:{Gallery, EventFeatures, InvestmentTable, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>
h3 {
    max-width: 1024px;
    margin:0 auto;
    margin-bottom:25px;
    padding:0 1em;
}      
#investment {
    &:before {
        content:" ";
        max-width: 1024px;
        margin:0 auto;
        width: 100%;
        height:1px;
        display: block;
        margin-top:35px;
        background:#D9D9D9;
        margin-bottom:35px;
    }    
    h3 {
        margin-top:95px;        
    }
}
</style>
