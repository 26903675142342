<template>
    <div class="donors">            
        <article v-for="(person, id) in donors" :key="id">            
            <div class='info-holder'>
                <div>
                    <p class="name uppercase" v-html="person.name"/>
                    <p v-if="person.info !== undefined" class="info" v-html="person.info"/>            
                </div>    
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        name:'EventFeatures',      
        data: () => ({ 
            donors: [
                { name: "Alfred Mann Family Foundation​"},
                { name: "Wallis Annenberg", info:"Chairman of the Board & CEO<br/>Annenberg Foundation​"},
                { name: "Brett Bittel", info:"President<br/>Bittel Enterprises​"},
                { name: "Jeanie Buss", info:"CEO & Governor<br/>Los Angeles Lakers​"},
                { name: "Victor Coleman", info:"Chairman & CEO<br/>Hudson Pacific Properties, Inc.​"},
                { name: "Gordon Crawford", info:"Philanthropist​"},
                { name: "James Ellis", info:"Former Dean<br/>USC Marshall School of Business​"},
                { name: "Bob Graziano", info:"Vice Chairman<br/>Private Bank, J.P. Morgan"},
                { name: "Susan Hirsch Wohl", info:"Director & CFO<br/>Bob & Nita Hirsch Family Foundation​"},
                { name: "Stephen Kaplan", info:"Chairman<br/>Nalpak Capital; Co-Chairman, DC United​"},
                { name: "Stan Kasten", info:"CEO<br/>Los Angeles Dodgers​"},
                { name: "Stan Kroenke", info:"Kroenke Sports Enterprises​"},
                { name: "Bennett Rosenthal", info:"Co-Managing Owner<br/>LAFC and Co-Founder, Ares Management​"},
                { name: "Gene Sykes", info:"Managing Director<br/>Goldman Sachs​"},
                { name: "Tom Tellefsen", info:"Principal<br/>Tellefsen Investments, Inc.​"},
                { name: "Wendy Wachtell", info:"President & CEO<br/>Joseph Drown Foundation"},
                { name: "Casey Wasserman", info:"Chairman & CEO<br/>Wasserman Media​"},
                { name: "Deloitte​"},
                { name: "Pillsbury Winthrop Shaw Pittman​"},
                { name: "David Jenkins​"},
            ]
        }),        
    }
</script>
<style lang="scss" scoped>    
    .donors {        
        max-width: 890px;
        margin:0 auto;         
        gap:10px;
        margin-bottom:35px;    
        display: grid;                    
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        article {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin:0;            
            background-color: #EDEDED;  
            padding:20px;
            margin-bottom: 10px;
            color: #161616;
            font-family: "Gotham Narrow";
            &:nth-child(2n) {
                background-color: #E1E1E1;  
            }
            .info-holder {   
                > div {
                    padding:12px 0;
                }             
                &:before, &:after {
                    width:30px;
                    margin:0 auto;
                    height:1px;
                    background:#C2C2C2;
                    display: block;
                    content:" ";
                }  
            }
            .name {
                font-size: 0.95em;
                font-weight: bold;
                letter-spacing: 2px;
                line-height: 1;
                margin:0;                
            }
            .info {
                display: inline-block;                                
                font-size: 13px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 20.8px;
                margin:0;
                margin-top:8px;
            }
        }
    }    
     @media (min-width:360px) {   
        .donors { 
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (min-width:450px) {   
        .donors { 
            grid-template-columns: repeat(3, 1fr);
        }
    }   
</style>