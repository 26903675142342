<template>
  <section id="expenses">
        <div class="toggle">
            <button @click="packageComparisonIsOpen = !packageComparisonIsOpen" :class="[packageComparisonIsOpen ? 'open' : '']">
                <span>Super Bowl 2022 Expenses</span>
                <ArrowButton invert :rotate="packageComparisonIsOpen"/>
            </button>            
        </div>       
        <transition name="fade" mode="out-in" >
            <div v-show="packageComparisonIsOpen">
            <div class="note">
                <p>Below are examples of expense line items related to the<br/>2022 Super Bowl (included but not limited to):</p>        
            </div>
                <div class="expenses-holder">                    
                    <div class="expenses">                                        
                        <div class="info">
                            <table>
                                <tr>
                                    <th class='label'>Expense Line Item</th>
                                </tr>
                                <tr v-for="(expense, id) in $store.state.superbowlExpenses" :key="id">
                                    <td>
                                        <p class="title" v-html="expense.title"/>
                                        <p class="description">{{expense.description}}</p>
                                    </td>                                    
                                </tr>
                            </table>
                        </div>
                    </div>                            
                </div>        
            </div>        
        </transition>
    </section> 
</template>

<script>
import ArrowButton from '@/patterns/ArrowButton';
export default {
    name:'SuperBowlExpenses',
    components:{ ArrowButton},
    data: () => ({
        packageComparisonIsOpen:false,        
        selectedType:0,
        selectedTab:0,
    }),    
    methods : {
        typeSelected(id) {
            this.selectedType = id; 
            this.selectedTab = 0;
        },
        tabSelected(id) {
            this.selectedTab = id; 
        }
    }
}
</script>

<style lang="scss" scoped>
    .note {
        max-width: 1024px;
        margin:45px auto;
        padding: 10px;
        p {
            color: #161616;
            font-family: "Gotham Narrow";
            font-size: 1em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1.7;
            text-align: left;
        }    
    }   
    table {
        color:#000;
        text-align: left;
        width: 100%;
        min-width: 350px;
        th {
            color: #FFFFFF;
            font-family: "Gotham Narrow";
            font-size: 1em;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1;
            width:340px;
            height:50px;            
            padding:1em;
            box-sizing: border-box;
            background: linear-gradient(90deg, #C2AB64 0%, #96844B 100%);            
        }
        .title, .description {
            color: #161616;
            font-family: "Gotham Narrow";
            font-size: 0.85em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1;
            font-weight: 300;
            text-transform: none;
            margin:0;
        }
        .title {
            font-size: 1em;
            font-weight: bold;
            text-transform: uppercase;
        }        
        .description {
            margin-top:6px;
            line-height: 1.5;
        }
        tr {
            td {
                background-color: #EDEDED;
                padding:1em;
                font-family: "Gotham Narrow";
                font-size: 1em;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 1;
                text-transform: uppercase;
            }
            &:nth-child(2n) td {
                background-color: #E1E1E1;
            }
        }
    }
    .expenses-holder {
        position: relative;
        padding-bottom: 95px;
        border-bottom:1px solid #414141;
        &:before {
            content: "";
            background: linear-gradient(135deg, #202020 0%, #161616 100%);
            position: absolute;
            bottom:0;
            left:0;
            width:100%;
            height:calc(100% - 35px);
        }
    }
    .info {
        overflow: auto;
        max-width: 1024px;
        width: 100%;
    }
    .expenses {
        max-width: 1044px;
        width: 100%;
        margin:0 auto;
        padding:10px;
        background:#fff;     
        margin-top:-25px;   
        position: relative;     
        box-sizing: border-box;   
    }        
    .toggle {
        margin:68px 0;
        position: relative;
        button {
            position: relative;
            background:#fff;
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
            width:100%;
            max-width: 1024px;
            border-radius: 3px;
            padding:25px;
            text-align: left;
            color: #161616;
            font-family: "Gotham Narrow";
            font-size: 1.3em;
            font-weight: bold;          
            cursor: pointer;   
            span, .arrow {
                vertical-align: middle;
            }
            .arrow {
                float:right;
            }                   
        }
        &:before {
            content:" ";
            height:1px;
            width: 100%;
            position: absolute;
            left:0;
            top:50%;
            background-color: #E1E1E1;
        }
    }
    h3 {
        max-width: 1024px;
        margin:0 auto;
        margin-bottom:25px;
        padding:0 1em;
    }      
    @media (min-width:560px) {       
    }
</style>