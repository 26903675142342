<template>
    <section>
        <Gallery title="ChampionLA" subtitle="Why ChampionLA?" :images="[
        {
            title:'',
            file:'why-championla.jpg'
        }
        ]"/>
        <section id="why-champion-la">
            <SectionIntro>
                <template v-slot:intro>
                    <h2>
                        <div class="uppercase">We strive to elevate our city with <span class="gold">Extraordinary experiences.</span></div>
                    </h2>            
                    <p><span class="gold">ChampionLA</span> was created with the vision to make Los Angeles<br />the most desired host city in the world. </p>
                </template>
            </SectionIntro>    
        </section>    
        <section class="highlights">
            <p>Hosting the world's  leading sporting events to drive <span class="gold">lasting social</span> and <span class="gold">economic impact</span> in our communities</p>
            <div class="image-holder right first"><div class="image"><img src="@/assets/highlights/why-championla_image1.jpg" alt=""></div></div>
            <div class="image-holder left"><div class="image"><img src="@/assets/highlights/why-championla_image2.jpg" alt=""></div></div>
            <div class="image-holder left"><div class="image"><img src="@/assets/highlights/why-championla_image3.jpg" alt=""></div></div>
            <p class="right override-top">Creating <span class="gold">thousands</span><br/>of jobs and injecting millions into local economies to build<br/>a <span class="gold">brighter</span>, more<br/><span class="gold">vibrant</span> city</p>
            <div class="right image-holder"><div class="image"><img src="@/assets/highlights/why-championla_image4.jpg" alt=""></div></div>
            <p class="left"><span class="gold">Rejuvenating industries</span> that have been impacted  most by COVID-19</p>
            <div class="left image-holder"><div class="image"><img src="@/assets/highlights/why-championla_image5.jpg" alt=""></div></div>
            <p class="right"><span class="gold">Elevating Los Angeles</span> by supporting the financial obligation of hosting <span class="gold">significant global events</span></p>
        </section>
        <Linear forcedPath='/champion-la/roi-on-events' forcedTitle='ROI on Events'/> 
    </section>
</template>

<script>

import Gallery from '@/patterns/Gallery';
import SectionIntro from '@/patterns/SectionIntro';
import Linear from '@/patterns/Linear';
export default {
    name:'WhyChampionLA',
    components:{Gallery, SectionIntro, Linear},
    data: () => ({ }),    
    mounted() {},
    beforeDestroy: function () {},
    methods: {},
};
</script>

<style lang="scss" scoped>
#why-champion-la h2 {
    max-width: 750px;
}
    .highlights {
        max-width: 1024px;
        margin:0 auto;
        padding:35px 0;
        padding-bottom:100px;
        position: relative;
        overflow: hidden;
        &:before {
            width:100%;
            max-width: 1024px;
            margin:0 auto;
            height:1px;
            background:#D9D9D9;            
            display: block;
            content:" ";
        }
        p {
            box-sizing: border-box;
            width: 100%;
            padding:1em;
            color:#161616;
            font-family: "Gotham Narrow";
            font-size: 2.1em;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 1.2;
            text-align: left;
        }
        .image-holder {                  
            .image {
                display: inline-block;
                vertical-align: top;
                box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                border-radius: 3px;
                overflow: hidden;
                margin-bottom:8px;
            }            
            img {
                vertical-align: top;
            }
        }
    }
    @media (min-width:1024px) {
        .highlights {
            overflow:auto;
            p {
                padding:0;
                margin:0;
                margin-top:95px;
                margin-bottom:90px;
                max-width: 340px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .first {
                position: absolute;
                top:5px;
                right:0;
            }
            .right {
                float:right;
                text-align: right;                                
            }      
            .left {
                float:left;
                clear: left;
            }   
            .override-top {
                position: relative;
                top:-50px;
            }
        }        
    }
    #why-champion-la {
        background: linear-gradient(135deg, #202020 0%, #161616 100%);
        display: flex;
        justify-content: center;
        align-items: center;    
        flex-direction: column; 
        padding-top:35px;   
        &:before {
            width:100%;
            max-width: 1024px;
            margin:0 auto;
            height:1px;
            background:#414141;
            content:" ";
        }
        > div {
            max-width: 1024px; 
            width:calc(100% - 1em);
            margin:2.25em auto;        
            padding-top:95px;
            padding-bottom:75px;
            border-top:1px solid #414141;
            border-bottom:1px solid #414141;                
        }
        p {
            &:last-child{
                margin-bottom:95px;
            }
        }
    }  
</style>
